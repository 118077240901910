@charset "utf-8";
/*  
 * Theme Name: Reactive Development Admin Styles
 * Theme URI: http://www.reactivedevelopment.net
 * Description: 100% custom wordpress admin theme.
 * Author: Jeremy Selph
 * Version: 3.0
 * License: Attribution-ShareAlike CC BY-SA
 * Link: https://creativecommons.org/licenses/by-sa/4.0/
 */

/* =============================================================================
   Main Structure
   ========================================================================== */ 
    
    #wpwrap{ background-image: url( '../img/admin/adminBar.png' );
        background-position:-147px 0; /* total width chnnage here ( + 17 ) ************************************************************* */ 
        background-repeat: repeat-y; padding:25px 25px 25px 20px; border:0; width:auto; }
    
    .media-php #wpwrap{ background-image:none !important; }
    
    #header-logo{ background-image:url( '../img/admin/home_icon.jpg' ) !important; background-position:0 -16px !important; 
        background-repeat:no-repeat !important; }

    #wpcontent{ margin-left:190px; /* total width chnnage here ( + 60 ) *************************************************************** */ }
    #wp-admin-bar-wp-logo{ display:none; }

/* =============================================================================
   Menu Styles
   ========================================================================== */ 

    #adminmenuback{ background-image:none; background-color:transparent; border:none; }
    #adminmenuwrap{ background-color:transparent; border:0; }
    #adminmenushadow, .adminmenushadow{ display:none; }
    #adminmenu, #adminmenuwrap, #adminmenuback{ width:130px !important; /* total width chnnage here ******************************** */ }
    #adminmenu ul{ width:140px; /* total width chnnage here (+ 10) ************************************************** */ display:block;
        background-color: #fff !important; /* new 3.5 */ }
    #adminmenu li{ padding:5px; border-bottom:none !important; border-top:none !important; }
    #collapse-menu{ display:none; }
    .wp-menu-separator{ padding:0 0 5px 0 !important; background-color:transparent !important; }
    #adminmenu div.separator{ border-color:#666 !important; }
    .separator{ margin:7px 0 0 10px; }

    /* make sre to set all widths */
    .sub-open{ left:150px !important; /* total width chnnage here ( + 20) ********************************************************** */ }
    .wp-menu-arrow{ left:172px !important; }

    #adminmenu .wp-submenu{ left: 150px; }
    #adminmenu .sub-open{ width:172px !important; 
        border-right:#CCC 1px solid !important; padding:0 !important; }
    #adminmenu .sub-open .wp-submenu-wrap{ width:172px !important; background-color:#FFF; }
    #adminmenu .sub-open li{ width:172px !important; }
    #adminmenu li.menu-top, .wp-menu-open .wp-submenu-wrap, .wp-menu-open .wp-submenu{ background-color: transparent !important; /* new 3.5 */
        width:140px !important; /* total width chnnage here ( + 10) **************************************************************** */ }

    #adminmenu li a{ -moz-border-radius:5px; -khtml-border-radius:5px; -webkit-border-radius:5px; border-radius:5px;  }
    #adminmenu li a:hover, #adminmenu li a:focus{ -moz-border-radius:0; -khtml-border-radius:0; -webkit-border-radius:0; border-radius:0; }
    #adminmenu li.wp-not-current-submenu a:hover, #adminmenu li.wp-not-current-submenu a:focus{
        -moz-border-radius:5px; -khtml-border-radius:5px; -webkit-border-radius:5px; border-radius:5px; }
    #adminmenu li.wp-menu-open a:hover, #adminmenu li.wp-menu-open a{
        -moz-border-radius:5px 5px 0 0; -khtml-border-radius:5px 5px 0 0; -webkit-border-radius:5px 5px 0 0; border-radius:5px 5px 0 0; }

    #adminmenu li.wp-has-submenu .wp-submenu li a:hover, #adminmenu li.wp-has-submenu .wp-submenu li a{ color: #999; /* 4.2.1 */
        -moz-border-radius:0; -khtml-border-radius:0; -webkit-border-radius:0; border-radius:0; }

    #adminmenu li.menu-top:hover > a, #adminmenu li.menu-top.focused > a, #adminmenu li.menu-top > a:focus {
        background-color:#666; text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4) !important; }

    a.menu-top{ border:0; border:none !important; background-color: #777777; 
        background-image: -moz-linear-gradient(center bottom , #6D6D6D, #808080); color:#FFF !important; }
    .wp-menu-arrow{ display:none !important; }
    .wp-submenu ul li a:hover, .wp-submenu ul li a:focus{ background-color:#CCC !important; }

/* =============================================================================
   Home Buttons
   ========================================================================== */ 

    .jsHome .wp-menu-image{ display:none; }
    .jsHome  a.wp-first-item{ background-color:transparent; background-repeat:no-repeat; background-image: url( '../img/admin/adminLogo_small.png' );
        background-position:bottom center; height:32px; /*width:88px;*/ /* total width chnnage here ( + 42 ) *************************** */ 
        text-indent:-1999px; margin:0 0 0 -1px; padding:0 !important;
        -moz-border-radius:0 !important; -khtml-border-radius:0 !important; -webkit-border-radius:0 !important; border-radius:0 !important; }
    #adminmenu .jsHome a:hover, #adminmenu .jsHome a:focus, #adminmenu .jsHome:hover, #adminmenu .jsHome:focus,
    #adminmenu li.jsHome.menu-top:hover > a, #adminmenu li.jsHome.menu-top.focused > a, 
    #adminmenu li.jsHome.menu-top > a:focus{ 
        background-color:transparent !important; }

/* =============================================================================
   Footer
   ========================================================================== */ 

    #wpfooter{ margin-left:210px; /* total width chnnage here ( + 60 ) *************************************************************** */
        padding:10px 20px 50px 20px; clear:both; }
    .widefat{ background-color:#FFF; }
    
/* ==========================================================================
   end styles
   ======================================================================= */

    /*/// wp 3.8 fixes //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
    
        #adminmenuback, #adminmenuwrap, #adminmenu{ background-color: transparent; }
        #adminmenu .jsHome2 div.wp-menu-image img{ display: none; }

        #adminmenu .wp-submenu li.current, #adminmenu .wp-submenu li.current a, #adminmenu .opensub .wp-submenu li.current a, 
        #adminmenu a.wp-has-current-submenu:focus+.wp-submenu li.current a, #adminmenu .wp-submenu li.current a:hover, 
        #adminmenu .wp-submenu li.current a:focus{ color: #2ea2cc; }

        .js #adminmenu .sub-open, .js #adminmenu .opensub .wp-submenu, #adminmenu a.menu-top:focus+.wp-submenu, 
        .no-js li.wp-has-submenu:hover .wp-submenu{ /*left: 150px;*/ }

        #adminmenu .wp-menu-arrow{ display: none !important; }
        .sticky-menu #adminmenuwrap{ position: relative !important; }

/* ==========================================================================
   mobile styles - 09/05/2014
   ======================================================================= */

    @media only screen and (max-width: 960px) {

        #wpwrap{ background-position: -400px 0; }
        #adminmenuback, #adminmenuwrap, #adminmenu{ width: 36px !important; }
        #adminmenu li.menu-top, .wp-menu-open .wp-submenu-wrap, .wp-menu-open .wp-submenu{ width: 36px !important; }
        .js #adminmenu .sub-open, .js #adminmenu .opensub .wp-submenu, #adminmenu a.menu-top:focus+.wp-submenu, 
        .no-js li.wp-has-submenu:hover .wp-submenu{ left: 46px; top: 5px; }
        .auto-fold #adminmenu .opensub .wp-submenu, .auto-fold #adminmenu .wp-has-current-submenu .wp-submenu.sub-open, 
        .auto-fold #adminmenu .wp-has-current-submenu a.menu-top:focus+.wp-submenu, 
        .auto-fold #adminmenu .wp-has-current-submenu.opensub .wp-submenu, .auto-fold #adminmenu .wp-submenu.sub-open, 
        .auto-fold #adminmenu a.menu-top:focus+.wp-submenu{ left: 46px; top: 5px; }
        #adminmenu .wp-submenu .wp-submenu-head{ color: #333; }
        .jsHome{ display: none; }
        #wpfooter{ margin-left: 66px !important; }

    }
    
    /* mobile phone starts here */
    @media only screen and (max-width: 782px) {

        #wpwrap{ background-position: -400px 0; }
        #wpwrap.wp-responsive-open{ background-position: -140px 0; }

        .wp-responsive-open #adminmenuback, .wp-responsive-open #adminmenuwrap, 
        .wp-responsive-open #adminmenu{ width: 150px !important; }
        .wp-responsive-open #adminmenu li.menu-top, .wp-responsive-open .wp-menu-open .wp-submenu-wrap, 
        .wp-responsive-open .wp-menu-open .wp-submenu{ width: 150px !important; }

    }

/* ==========================================================================
   plugin fixes
   ======================================================================= */

    .fa-live-preview{ display: none !important; }
    #widgets-right .widgets-holder-wrap .description{ padding-left: 0; }
    .spo-updating-row .check-column {
        background: url( '../../../../wp/wp-admin/images/spinner.gif' ) 10px 9px no-repeat;
    }

    @media print,
    (-o-min-device-pixel-ratio: 5/4),
    (-webkit-min-device-pixel-ratio: 1.25),
    (min-resolution: 120dpi) {
        
        .spo-updating-row .check-column {
            background-image: url( '../../../../wp/wp-admin/images/spinner-2x.gif' );
            background-size: 20px 20px;
        }

    }