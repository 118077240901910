@charset "utf-8";
/*  
 * Theme Name: Reactive Development Admin Styles
 * Theme URI: http://www.reactivedevelopment.net
 * Description: 100% custom wordpress admin theme.
 * Author: Jeremy Selph
 * Version: 3.0 
 * License: Attribution-ShareAlike CC BY-SA
 * Link: https://creativecommons.org/licenses/by-sa/4.0/
 */

/* =============================================================================
   Login Page styles
   ========================================================================== */ 

    .login { /* get colors from http://www.colorzilla.com/gradient-editor/ */
        min-height:100%;
		background:#fff !important;
       	margin:0 0 0 0 !important;
    }

    .login h1 a {
        background:url( '../img/admin/adminLogo.png' ) top center no-repeat !important;
        height:64px !important;
        width:285px !important;
        background-size:100%, 100%;  
        margin-left: 12px !important;
        margin-bottom: 15px !important;
        padding-bottom: 0px;
        
        /* comment out the rest of this */
        /* position: absolute;
         done commenting */
        
    }

    .login form{ /*box-shadow:none !important; background-color: #9eda37;*/ }

    #login{ padding:50px 0 0 0 !important; }

    #nav, #nav a, .login #backtoblog a, .login #nav a{ color:#000 !important; text-shadow:none !important; }
    #nav, #nav a:hover, .login #backtoblog a:hover, .login #nav a:hover{ color:#000 !important; }

    #login_error, .message{ /*font-size:20px !important;*/ }
    .login #backtoblog{ /*padding:0 0 0 0 !important;*/ margin: 0;  }